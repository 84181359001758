<template>
  <form @submit.prevent="saveProspectDetails" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        v-model="prospectDetails.name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Enquiry Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 1</label>
      <input
        v-model="prospectDetails.address1"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 1..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 2</label>
      <input
        v-model="prospectDetails.address2"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 2..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 3</label>
      <input
        v-model="prospectDetails.address3"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 3..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">City</label>
      <input
        v-model="prospectDetails.city"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="City..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">County</label>
      <input
        v-model="prospectDetails.county"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="County..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Postcode</label>
      <input
        v-model="prospectDetails.postcode"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Postcode..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Country</label>
      <div class="inline-block relative w-full">
        <select v-model="prospectDetails.country" class="bge-input bge-select rounded">
          <optgroup label="Frequently Used">
            <option value="United Kingdom">United Kingdom</option>
            <option value="Kenya">Kenya</option>
            <option value="England">England</option>
            <option value="Scotland">Scotland</option>
            <option value="Wales">Wales</option>
            <option value="Northern Ireland">Northern Ireland</option>
            <option value="Republic of Ireland">Republic of Ireland</option>
          </optgroup>
          <optgroup label="All Countries">
            <template v-for="(country, i) in countries">
              <option :key="i" :value="country">{{ country }}</option>
            </template>
          </optgroup>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Website</label>
      <input
        v-model="prospectDetails.website"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Website..."
      />
    </div>
    <div class="w-full">
      <button
        type="button"
        @click="saveProspectDetails"
        class="flex items-center float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Enquiry Details</span>
      </button>
    </div>
  </form>
</template>

<script>
import { COUNTRIES } from "@/utils/constants";

export default {
  name: "EditProspectDetails",
  data() {
    return {
      countries: []
    };
  },
  props: {
    prospectDetails: {
      required: true,
      type: Object
    }
  },
  mounted() {
    this.countries = COUNTRIES;
  },
  methods: {
    saveProspectDetails: function() {
      this.$emit("complete");
    }
  }
};
</script>